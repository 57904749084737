.modal {
    /* This class will be applied to the outermost Modal container */
}

.content {
    background-color: var(--colors-bg-card);
}

.header {
    background-color: var(--colors-bg-card);
}

.body {
    background-color: var(--colors-bg-card);
}
